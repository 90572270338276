<template>
    <div v-if="isVisible" class="hidden pointer-events-none fixed w-screen h-screen left-0 top-0 overflow-y-scroll lg:flex flex-col pt-[32px] px-[16px] z-[100]">
        <div class="pointer-events-auto mt-auto left-1/2 -translate-x-1/2 w-full max-w-[1220px] bg-[#FFF] rounded-t-[20px] menu-shadow relative">
            <button class="w-[32px] h-[32px] rounded-full bg-[#9DA4AE] items-center justify-center flex absolute -top-[8px] -right-[8px]" @click="close()">
                <img src="@assets/images/icons/x-close.svg" alt="" width="20" height="20" class="filter-white">
            </button>
            <table class="w-full">
                <thead>
                    <tr class="grid grid-cols-[200px_1fr_1fr_1fr] gap-[16px] p-[16px]">
                        <th class="flex flex-col gap-[10px]">
                            <button class="w-[32px] h-[32px] bg-[#2E90FA] rounded-full flex items-center justify-center"
                                :class="{'rotate-180': !isOpen }"
                                @click="toggleExpand()"
                            >
                                <img width="20" height="20" src="@assets/images/icons/chevron-down.svg" alt="" class="filter-white">
                            </button>
                            <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px] ">
                                Compare<br>Experts
                            </span>
                        </th>
                        <th v-for="data in store.profiles" :key="data" class="flex flex-col text-start">
                            <div class="w-max relative">
                                <button class="absolute left-0 top-0 w-[20px] h-[20px] bg-[#9DA4AE] rounded-full z-[10]" @click="onRemove(data)">
                                    <img src="@assets/images/icons/minus.svg" alt="" class="filter-white">
                                </button>
                                <div class="w-[80px] h-[80px] rounded-full lazy-container">
                                    <img class="lazy" :src="data.profile_picture.url" alt="" onload="this.classList.add('loaded')" loading="lazy"/>
                                    <img class="fallback" src="@assets/images/placeholders/profile-picture.svg" alt="" />
                                </div>
                            </div>
                            <div>
                                <div class="flex flex-col gap-[4px] grow">
                                    <span class="text-base font-medium text-[#0A0D14] text-ellipsis overflow-hidden">{{ data.full_name }}</span>
                                    <span v-if="data.current_job" class="font-normal text-sm text-[#525866] text-ellipsis overflow-hidden">
                                        {{ data.current_job.position }} @{{ data.current_job.company }}
                                    </span>
                                    <span v-if="data.headline" class="font-normal text-ellipsis overflow-hidden text-[#0A0D14]">
                                        {{ data.headline }}
                                    </span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-show="isOpen" class="gap-y-[16px] flex flex-col pb-[16px]">
                    <tr class="bg-[#F9FAFB] grid grid-cols-[200px_1fr_1fr_1fr] gap-[16px] p-[16px]">
                        <td>
                            <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px] ">
                                Bio
                            </span> 
                        </td>
                        <td v-for="data in store.profiles" :key="data" class="flex flex-col gap-[16px] text-start">
                            <p class="text-ellipsis line-clamp-[8]">
                                {{ this.sanitize(data.bio) }}
                            </p>
                        </td>
                    </tr>

                    <tr class="grid grid-cols-[200px_1fr_1fr_1fr] gap-[16px] px-[16px]">
                        <td>
                            <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px]">
                                Forensic<br>Investigations
                            </span> 
                        </td>
                        <td v-for="data in store.profiles" :key="data" class="flex flex-col gap-[16px] text-start">
                            <div class="grid grid-cols-2 gap-[16px]">
                                <figure v-for="photo in getInvestigationPhotos(data)" :key="photo" class="aspect-square rounded-[4px] bg-[#D9D9D9] overflow-hidden">
                                    <img :src="photo.url" alt="" class="object-fit mx-auto w-full h-full">
                                </figure>
                            </div>
                        </td>
                    </tr>

                    <tr class="bg-[#F9FAFB] grid grid-cols-[200px_1fr_1fr_1fr] gap-[16px] p-[16px]">
                        <td>
                            <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px]">
                                Litigation<br>Experience
                            </span> 
                        </td>
                        <td v-for="data in store.profiles" :key="data" class="flex flex-col gap-[16px] text-start">
                            <p class="text-ellipsis line-clamp-[8]">
                                {{ data.litigation_experience }} Deposition/Testimony in the past 4 years.
                            </p>
                        </td>
                    </tr>

                    <tr class="grid grid-cols-[200px_1fr_1fr_1fr] gap-[16px] px-[16px]">
                        <td>
                            <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px]">
                                Address
                            </span> 
                        </td>
                        <td v-for="data in store.profiles" :key="data" class="flex flex-col gap-[16px] text-start">
                            <p class="text-ellipsis line-clamp-[8]">
                                {{ data.address }}
                            </p>
                        </td>
                    </tr>

                    <Accordion title="Career Experience" class="px-[16px] w-full">
                        <div class="py-[16px]">
                            <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px] ">
                                Experience
                            </span> 
                        </div>
                        <div v-for="data in store.profiles" :key="data" class="flex flex-col gap-[16px] text-start py-[16px] truncate">
                            <Experience v-for="job in data.job_experiences" :key="job" :data="job" />
                        </div>
                    </Accordion>
                    
                    <Accordion title="Education" class="px-[16px] w-full">
                        <div class="py-[16px]">
                            <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px] ">
                                Education
                            </span> 
                        </div>
                        <div v-for="data in store.profiles" :key="data" class="flex flex-col gap-[16px] text-start py-[16px] truncate">
                            <Experience v-for="education in data.educations" :key="education" :data="education"/>
                        </div>
                    </Accordion>

                    <Accordion title="Professional Affiliations" class="px-[16px] w-full">
                        <div class="py-[16px]">
                            <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px] ">
                                Affiliations
                            </span> 
                        </div>
                        <div v-for="data in store.profiles" :key="data" class="flex flex-col gap-[16px] text-start py-[16px] truncate">
                            <div class="flex flex-col gap-2">
                                <div v-for="affiliation in data.professional_affiliations" :key="affiliation">
                                    <span class="block text-[#1E1E1E] font-semibold text-base truncate">
                                        {{ affiliation.organization }}
                                    </span>
                                    <span class="block text-[#1F2A37] text-sm truncate">
                                        {{ affiliation.title }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Accordion>

                    <Accordion title="Credentials & Hourly Rates" class="px-[16px] w-full">
                        <div class="col-span-4 grid grid-cols-[182px_1fr_1fr_1fr] px-[16px] gap-[16px]">
                            <div class="py-[16px]">
                                <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px] ">
                                    Discipline
                                </span> 
                            </div>
                            <div v-for="data in store.profiles" :key="data" class="py-[16px] truncate">
                                <span class="block text-[#525866] text-sm font-semibold">{{ data.discipline.name }}</span>
                            </div>
                        </div>
                        <div class="bg-[#F9FAFB] col-span-4 grid grid-cols-[182px_1fr_1fr_1fr] px-[16px] gap-[16px]">
                            <div class="py-[16px]">
                                <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px] ">
                                    Other Credentials
                                </span> 
                            </div>
                            <div v-for="data in store.profiles" :key="data" class="flex flex-col gap-[16px] text-start py-[16px] truncate">
                                <div class="flex flex-col gap-2">
                                    <span v-for="credential in data.credentials" :key="credential" class="block text-[#384250] font-medium text-base truncate">
                                        {{ credential.title }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-4 grid grid-cols-[182px_1fr_1fr_1fr] px-[16px] gap-[16px]">
                            <div class="py-[16px]">
                                <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px] ">
                                    Licensed States
                                </span> 
                            </div>
                            <div v-for="data in store.profiles" :key="data" class="flex flex-col gap-[16px] text-start py-[16px] truncate">
                                <div class="flex flex-wrap gap-2">
                                    <span v-for="license in data.licensed_states" :key="license" class="block w-max bg-[#F3F4F6] p-[4px_8px] flex gap-1 rounded-[6px] items-center truncate font-semibold">
                                        {{ state(license.state) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="bg-[#F9FAFB] col-span-4 grid grid-cols-[182px_1fr_1fr_1fr] px-[16px] gap-[16px]">
                            <div class="py-[16px]">
                                <span class="text-[24px] text-[#0A0D14] font-medium text-start block leading-[34px] ">
                                    Hourly Rates
                                </span> 
                            </div>
                            <div v-for="data in store.profiles" :key="data" class="flex flex-col gap-[16px] text-start py-[16px] truncate">
                                <div class="flex flex-col gap-2">
                                    <div>
                                        <span class="block text-[#525866] text-sm font-semibold">Forensic Rate</span>
                                        <span class="block text-[#525866] text-sm">{{ money(data.forensics_hourly_rate) }}</span>
                                    </div>
                                    <div>
                                        <span class="block text-[#525866] text-sm font-semibold">Travel Rate</span>
                                        <span class="block text-[#525866] text-sm">{{ money(data.travel_rate) }}</span>
                                    </div>
                                    <div>
                                        <span class="block text-[#525866] text-sm font-semibold">Expert Witness Rate</span>
                                        <span class="block text-[#525866] text-sm">{{ money(data.witness_hourly_rate) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Accordion>

                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { STATES } from "@/utils/states";
import useCompareStore from "@/stores/Compare";
import Accordion from "@/components/interfaces/compare/Accordion.vue";
import Experience from "@/components/interfaces/compare/Experience.vue";

export default {
    setup: () => ({
        store: useCompareStore()
    }),
    components: {
       Accordion,
       Experience
    },
    data: () => ({
        isVisible: false,
        isOpen: false
    }),
    computed: {
        profileCount() {
            return this.store.profiles.length;
        }
    },
    watch: {
        profileCount(value) {
            this.isVisible = value > 0;
            if (value == 0) this.isOpen = false;
        }
    },
    methods: {
        close() {
            this.store.removeAllProfiles();
            document.querySelector("body").style.overflow = null;
        },
        toggleExpand() {
            this.isOpen = !this.isOpen;
            document.querySelector("body").style.overflow = this.isOpen ? "hidden": null;
        },
        onRemove(profile) {
            this.store.removeProfile(profile);
        },
        sanitize(string) {
            return string.replace(/<[^>]*>|\s+/g, ' ').trim()
        },
        getInvestigationPhotos(profile) {
            return profile.forensic_investigations
                .filter(o=>o.photos.length > 0)
                .slice(0, 4)
                .map(o=>o.photos[0]);
        },
        money(value) {
            const MoneyFormat = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0
            });

            return MoneyFormat.format(value);
        },
        state(value) {
            return STATES[value];
        }
    }
}
</script>
<style scoped>
.aspect-square {
    aspect-ratio: 1;
}

.menu-shadow {
    box-shadow: 8px 4px 35px 0px rgba(0, 0, 0, 0.10), -6px -11px 23px 0px rgba(0, 0, 0, 0.07);
}
</style>